<template>
  <div>
    <tgl-card-header-mobile
      ><h1>Dispositivo {{ device.alias }}</h1></tgl-card-header-mobile
    >
    <tgl-card-content-mobile>
      <div class="row">
        <div class="col-12 col-sm-6">
          <device-info :device="device" />
        </div>
        <div class="col-12 col-sm-6" v-if="showMap">
          <md-card>
            <md-card-content>
              <device-map :device="device" />
            </md-card-content>
          </md-card>
        </div>
      </div>
    </tgl-card-content-mobile>
  </div>
</template>

<script>
import DeviceInfo from "@/components/Mobile/Devices/DeviceInfo";
import DeviceMap from "@/components/Mobile/Devices/DeviceMap";
import TglCardHeaderMobile from "@/components/Mobile/Cards/TglCardHeaderMobile.vue";
import TglCardContentMobile from "@/components/Mobile/Cards/TglCardContentMobile.vue";
export default {
  components: {
    DeviceInfo,
    DeviceMap,
    TglCardHeaderMobile,
    TglCardContentMobile,
  },
  data: () => ({
    device: {},
    showMap: false,
  }),
  methods: {
    getDevice() {
      this.$store
        .dispatch("device/getDevice", this.$route.params.device)
        .then((data) => {
          this.device = data;
          if (this.device.position !== null) {
            this.showMap = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getDevice();
  },
};
</script>